export const manipulateTemplate = (html) => {
  const container = document.createElement('div');
  container.innerHTML = html;
  const anchors = container.querySelectorAll('a');
  anchors.forEach((anchor) => {
    anchor.setAttribute('target', '_blank');
  });
  const manipulatedHtml = container.innerHTML;
  return manipulatedHtml;
};

export const copyToClipboard = (
  referralCode,
  dispatch,
  setCustomAlert,
  message
) => {
  const el = document.createElement('textarea');
  el.value = referralCode;
  el.setAttribute('readonly', '');
  el.style = { position: 'absolute', left: '-9999px' };
  document.body.appendChild(el);
  el.select();
  el.setSelectionRange(0, 99999);
  navigator.clipboard.writeText(el.value);
  document.body.removeChild(el);
  dispatch(
    setCustomAlert({
      message: message || 'Link Copied!',
      state: true,
      type: 'success',
      position: 'top-right',
    })
  );
};

export const copyAll = (array, dispatch, setCustomAlert, message) => {
  let text = '';
  let wordLength = 0;
  array.forEach((element, index) => {
    text += element.text;
    if (index !== array?.length - 1) {
      text += '\n';
    }
    wordLength += element.text.split(/(\s+)/).filter((f) => f !== ' ').length;
  });
  copyToClipboard(text, dispatch, setCustomAlert, message);
  return wordLength;
};
